// main.js

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router"; // Import your Vue Router instance
import { createI18n } from "vue-i18n";
import en from "./locales/en.js"; // Import English translations
import fr from "./locales/fr.js"; // Import French translations
import moment from "moment";

const app = createApp(App);

// Make Moment.js available globally via `app.config.globalProperties`
app.config.globalProperties.$moment = moment;
moment.locale("fr"); // Default locale

const i18n = createI18n({
  warnHtmlInMessage: "off", // disable of the Detected HTML in message

  locale: "fr", // default locale
  fallbackLocale: "fr",
  messages: {
    en,
    fr,
  },
});

app.use(i18n);
app.use(router);

// Mount the root component to the DOM
app.mount("#app");
