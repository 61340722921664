<template>
  <section class="blog section" id="blog">
    <div class="container-full">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <h2>{{ $t("blogSection.title") }}</h2>
            <img src="img/section-img.png" alt="#" />
            <p>{{ $t("blogSection.description") }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12"
          v-for="(item, index) in items"
          :key="index"
        >
          <!-- Single Blog -->
          <div class="row single-news">
            <div class="col-lg-3 news-head">
              <img
                class="custom-image"
                :src="'https://api.wamerci.com/' + item.profile_image"
                alt="#"
              />
            </div>
            <div class="col-lg-9 news-body">
              <div class="news-content">
                <h2 class="limited-title">
                  <a href="/download-app">{{ item.title }}</a>
                </h2>

                <div class="row">
                  <div class="col-lg-9">
                    <p class="limited-title text">
                      {{ item.address }}
                    </p>
                    <p class="text">
                      <strong>
                        {{
                          $moment(item.created_at).format("dddd, MMMM Do YYYY")
                        }}</strong
                      >
                    </p>
                  </div>

                  <div class="col-lg-3">
                    <h2>{{ Math.round(item.price) }}F</h2>
                  </div>
                </div>
                <p class="text limited-description">
                  {{ item.description }}
                </p>

                <div class="row">
                  <div class="col-lg-6">
                    <p class="text">
                      <strong>Offers ({{ item.offers_count }})</strong>
                    </p>
                  </div>

                  <div class="col-lg-6">
                    <p class="text">
                      <strong>Questions ({{ item.questions_count }})</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Single Blog -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "TaskListComponent",
  data() {
    return {
      items: [],

      loading: false, // To keep track of the form submission status
    };
  },
  mounted() {
    this.getTaskList();
  },
  methods: {
    async getTaskList() {
      try {
        //Send form data to server
        this.loading = true;

        const response = await axios.get(
          "https://api.wamerci.com/api/v1/get_task_list_web"
        );

        this.loading = "success";
        // Clear form data after successful submission
        this.items = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.custom-image {
  object-fit: cover; /* Ensures the image covers the defined area */
}
</style>
